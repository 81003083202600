<template>
  <v-container>
    <app-loading v-if="loading" />
    <v-row v-else>
      <v-col
        md="12"
        cols="12"
      >
        <app-data-table
          :title="
            $vuetify.lang.t('$vuetify.titles.list', [
              $vuetify.lang.t('$vuetify.menu.company_subscription')
            ])
          "
          csv-filename="Categories"
          :headers="getTableColumns"
          :items="companies"
          :activeAccountButton="true"
          :viewNewButton="false"
          :viewEditButton="false"
          :viewDeleteButton="false"
          :manager="'supplier'"
          :sort-by="['firstName']"
          :sort-desc="[false, true]"
          multi-sort
          @active-row="suspendCompany"
        >
            <template v-slot:[`item.nameCountry`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-avatar left>
                                {{ item.countryFlag }}
                            </v-avatar>
                            {{ item.country }}
                        </v-chip>
                    </template>
                    <span>{{ item.nameCountry }}</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.plan_name`]="{ item }">
                {{ item.plan.length > 0 ? item.plan[0].name : $vuetify.lang.t('$vuetify.no_defined') }}
            </template>
            <template v-slot:[`item.state_text`]="{ item }">
                {{ item.plan.length > 0 ? $vuetify.lang.t('$vuetify.subscription_state.' + item.state_text) : $vuetify.lang.t('$vuetify.no_defined') }}
            </template>
        </app-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'CompanySubscriptionSuspendend',
  data () {
    return {
      suspend: {
        suspendDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
        description: '',
        company: null

      },
      formRule: this.$rules,
      formValid: false,
      dialog: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters('statics', ['smallScreen']),
    ...mapState('company', ['companies', 'isActionInProgress']),
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.email'),
          value: 'email'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.country'),
          value: 'nameCountry',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.phone'),
          value: 'phone',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.name'),
          value: 'plan_name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.ends_at'),
          value: 'suspend_at',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    companies: function () {
    }
  },
  async created () {
    this.loading = true
    await this.getCompaniesSuspend({ status: 'suspend' })
    this.loading = false
  },

  methods: {
    ...mapActions('company', ['suspendAccount', 'getCompaniesSuspend']),
    suspendCompany ($event) {
      this.suspend.company = $event
      this.suspend.status = 'active'
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.actions.active') + ' ' + this.$vuetify.lang.t('$vuetify.menu.account'),
          text: '',
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.active'
          ),
          confirmButtonColor: 'primary'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.activeCompany()
          }
        })
    },
    async activeCompany () {
      await this.suspendAccount(this.suspend)
      await this.getCompaniesSuspend({ status: 'suspend' })
    }

  }
}
</script>

<style scoped>

</style>
